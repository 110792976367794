@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,700;1,400;1,700&family=Open+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap');
body {
  margin: 0;
  padding: 0;
  font-family: 'Open Sans', sans-serif;
  padding-bottom: 5em;
}

nav.navbar {
  padding: 0 1em;
  height: 70px;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.9);
  box-shadow: 0 1px 1px black;
  text-shadow: 1px 1px 1px black;
  font-family: 'Montserrat', sans-serif;

  display: flex;
  justify-content: space-between;
  align-items: center;

  position: fixed;
  top: 0;
  bottom: 0;
}

nav .navbar-brand {
  font-weight: bold;
}

nav .navbar-button {
  display: none;
  color: grey;
}

nav .navbar-button:hover {
  color: white;
  cursor: pointer;
}

nav .navbar-collapse {
  width: 70%;
}

nav .navbar-collapse-menu {
  list-style-type: none;
  font-size: 0.8em;

  display: flex;
  justify-content: space-around;
  align-items: center;
}

nav.navbar a {
  text-decoration: none;
  color: white;
}

nav .navbar-collapse-menu a {
  text-decoration: none;
  color: grey;
}

nav.navbar li:hover {
  text-decoration: underline;
}

.menu-item-selected {
  text-decoration: underline;
}

.menu-item-selected a {
  color: white !important;
}

header.header {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;

  height: 70vh;
  margin-bottom: 5em;
  padding-bottom: 1.5em;
  font-family: 'Montserrat', sans-serif;

  background-size: cover;
  background-position: center;
}

header.header h1 {
  text-shadow: 1px 1px 1px grey;
}

header.header h2 {
  text-shadow: 0.8px 0.8px 0.8px grey;
  margin-top: -1px;
}

header.header p {
  text-decoration: underline;
}

main.container {
  width: 90%;
  max-width: 600px;
  margin: 0 auto;
  padding: 0 1em;
}

main.container a {
  color: black;
}

.about-content {
  white-space: pre-line;
}

ul.experience-list,
ul.education-list {
  display: flex;
  flex-direction: column;
  gap: 2em;
}

li.experience-list-item span,
li.education-list-item span,
li.experience-list-item a,
li.education-list-item a {
  font-size: 0.6em;
  font-style: italic;
}

li.projects-list-item,
li.dev-list-item {
  border: 0.5px solid black;
  padding: 1em;
  padding-bottom: 2em;
  margin: 1em 0.5em;
  box-shadow: 3px 3px black;

  display: flex;
  align-items: center;
  gap: 2em;
}

li.projects-list-item img,
li.dev-list-item img {
  width: 40%;
}

li.projects-list-item div,
li.dev-list-item div {
  width: 50%;
  margin-top: -15px;
}

ul.contact-links-list {
  display: flex;
  flex-direction: column;
  margin-left: 2em;
}

.dev-list-item-links a,
.home-cta a {
  padding: 0.3em;
  margin-right: 1em;
  text-decoration: none;
  border: 0.5px solid black;
  box-shadow: 1px 1px black;
  cursor: pointer;
}

.dev-list-item-links a:hover,
.home-cta a:hover {
  box-shadow: 2px 2px black;
}
.dev-list-item-links a:active,
.home-cta a:active {
  box-shadow: none;
}

.home-cta {
  margin-top: 2em;
  text-align: center;
  font-size: 1.2em;
}

@media only screen and (max-width: 570px) {
  nav.navbar {
    height: fit-content;
    min-height: 60px;
  }

  nav .navbar-collapse {
    display: none;
    width: 25%;
    margin-right: 4.5em;
  }

  nav .navbar-collapse-menu {
    flex-direction: column;
    align-items: start;
  }

  nav .navbar-button {
    display: block;
    margin-right: 4.5em;
  }

  li.projects-list-item,
  li.dev-list-item {
    flex-direction: column;
  }

  li.projects-list-item div,
  li.dev-list-item div {
    width: 90%;
    margin: 0 auto;
  }

  li.projects-list-item img,
  li.dev-list-item img {
    width: 90%;
  }
}
